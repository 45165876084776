<template>
    <div class="title-flex">
        <h1>{{ offre.titre }}</h1>
    </div>
    <div class="row" v-if="offre">
        <div class="col-lg-12" v-if="offre.etat == 'D'">
            <div class="alert alert-warning">
                Votre offre n'est pas publiée. Vous pouvez encore l'éditer :
                <router-link :to="{ name: 'offre_create', params: { uuid: offre.uuid }}" title="Editer l'offre"><i class="fa fa-edit"></i> Modifier </router-link>
            </div>
            <div class="alert alert-info">
                Votre offre est validée. Vous pouvez la mettre en ligne.
                <button class="btn btn-default" :disabled="!isPublishable" @click="publish()">Mettre en ligne</button>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="ibox">
                <div class="ibox-content">
                    <div v-if="offre.id">
                        <h2>Offre</h2>
                        <ul class="ibox-list">
                            <li><strong>Titre :</strong> {{ offre.titre }}</li>
                            <li><strong>Type :</strong> {{ offre.type?.libelle }}</li>
                            <li><strong>Etablissement :</strong> {{ offre.etablissement.titre }} </li>
                            <li><strong>Intervenants :</strong>
                            <span v-for="intervenant in offre.intervenants" :key="intervenant.id">
                                {{ intervenant.prenom }} {{ intervenant.nom }}
                            </span></li>
                            <li><strong>Nb Participant min : </strong>{{ offre.nb_participant_min }}</li>
                            <li><strong>Nb Participant max : </strong>{{ offre.nb_participant_max }}</li>
                        </ul>
                        <h2>Prix</h2>
                        <ul class="ibox-list">
                            <li><strong>Prix expérience :</strong> {{ offre.prix_experience }} €</li>
                            <li><strong>Prix produit :</strong> {{ offre.prix_produit }} €</li>
                            <!-- <li><strong>Prix livraison :</strong> {{ offre.prix_livraison}} €</li> -->
                        </ul>
                        <h2>Déclinaisons</h2>
                        <div v-for="declinaison in offre.declinaisons" :key="declinaison.id">
                            {{ declinaison.libelle }}
                            {{ declinaison.prix }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="ibox" v-if="offre.type?.has_produit">
                <div class="ibox-content">
                    <h2>Produits</h2>
                    <ul class="ibox-list">
                        <div v-for="produit in offre.produits" :key="produit.id">
                            <li><strong>{{ produit.produit.titre }}</strong> <i class="fas fa-times"></i> {{ produit.quantite }}</li>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="ibox">
                <div class="ibox-content">
                    <div class="title-flex">
                        <h2>Capsules</h2>
                        <button class="btn btn-primary m-b-lg" @click="showFormCapsule = true"><i class="fa fa-plus"></i> Ajouter une capsule</button>

                    </div>
                    <CapsuleCreator :offre="offre" v-if="showFormCapsule" @add-capsule="addCapsule"/>
                    <ul class="ibox-list">
                        <div v-for="capsule in offre.capsules" :key="capsule.id">
                            <li>
                                <img v-if="capsule.image" :src="capsule.image.full_path + '?w=200'" alt="">
                                <strong>{{ capsule.titre }}</strong> {{ capsule.id }}
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
          <!-- <div class="ibox" v-if="offre.etat == 'O'">
             <div class="ibox-content">

                  <button @click="unpublish()" title="Editer l'offre" class="btn btn-warning"><i class="fa fa-edit"></i> Dépublier </button>
              </div>
          </div> -->
            <!-- Dates -->
            <div class="ibox" v-if="offre.type?.has_reservation && offre.dates">
                <div class="ibox-content">
                    <h2>Dates</h2>
                    <DateSelector v-if="offreSource.id"
                        :offre="offreSource"
                        :height="600"
                        :save-url="saveDatesUrl"
                        :delete-url="deleteDateUrl"
                    >
                    </DateSelector>
                </div>
            </div>

            <!-- Vidéo expérience -->
            <div class="ibox">
                <div class="ibox-content">
                    <h2>Video de l'experience</h2>
                    <VimeoUploader v-model="offre.video"
                        :uploadurl="uploadVideoUrl"
                        :videoid="offre?.video?.fichier"
                        @add-video="addVideo"
                        @delete-video="removeVideo" />
                </div>
            </div>

            <!-- Vidéo -->
            <div class="ibox">
                <div class="ibox-content">
                    <h2>Video</h2>
                    <VimeoUploader v-model="offre.video"
                        :uploadurl="uploadVideoUrl"
                        :videoid="offre?.video?.fichier"
                        @add-video="addVideo"
                        @delete-video="removeVideo" />
                </div>
            </div>

            <!-- Images -->
            <div class="ibox">
                <div class="ibox-content">
                    <div class="title-flex">
                        <h2>Images</h2>
                        <span class="m-b-lg">
                            <FileUploader :uploadurl="uploadImageUrl" @add-document="addDocument"  />
                        </span>
                    </div>

                    <div class="ibox-image" :class="{ 'active': image.id == offre.image.id, '': image.id != offre.image.id}" v-for="image in offre.gallery" :key="image.id">
                        <img :src="image.full_path" width="200" alt="">

                        <div class="buttons">
                            <button class="btn" :class="{ 'btn-secondary': image.id == offre.image.id, 'btn-default': image.id != offre.image.id}" @click="setDefaultImage(image)">Image Principale</button>
                            <button class="btn btn-delete" @click="removeDocument(image)"><i class="fas fa-trash-alt"></i></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import moment from "moment";
import VimeoUploader from "@/components/VimeoUploader";
import FileUploader from "@/components/FileUploader";
import DateOffre from "@/core/models/DateOffre"
import CapsuleCreator from '@/components/CapsuleCreator.vue';
import DateSelector from "@/components/DateSelector";

export default {
  name: "Offre",
  data() {
    return {
      offre: {},
      newDate: new Date(),
      views: ['month', 'week', 'day'],
      currentDate: new Date(),
      showFormCapsule: false
    };
  },
  validations() {
      return {
        offre: {},
      }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {
    fetchData() {

      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/offre/" +
            this.$route.params.uuid,
          {}
        )
        .then((resp) => {
          loading.close();
          this.offre = resp.data;          
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    postOffre() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      axios
        .post(
          process.env.VUE_APP_API + "/producteur/offre",
          this.offre
        )
        .then((resp) => {
          this.offre = resp.data;
          loading.close();
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    publish() {
        this.offre.etat = 'O';
        this.offre.etat = 'O';
        this.postOffre()
    },
    unpublish() {
      this.offre.etat = 'D';
      this.postOffre()
    },
    addDate() {
      let dateOffre =  new DateOffre();
      dateOffre.date = moment(this.newDate).format();
      this.offre.dates.push(dateOffre)
      this.postOffre()
    },
    removeDate(date) {
      this.offre.dates = this.offre.dates.filter(function( obj ) {
          return obj.id !== date.id;
      });
      this.postOffre()
    },
    setDefaultImage(image){
      this.offre.image = image
      this.postOffre()
    },
    addDocument(doc){
      this.offre.gallery.push(doc)
      this.postOffre()
    },
    removeDocument(doc){
      this.offre.gallery = this.offre.gallery.filter(function( obj ) {
          return obj.id !== doc.id;
      });
      this.postOffre()
    },
    addVideo(doc){
      this.offre.video = doc;
      this.postOffre()
    },
    removeVideo(){
      this.offre.video = null;
      this.postOffre()
    },
    addVideoExperience(doc){
      this.offre.video_experience = doc;
      this.postOffre()
    },
    removeVideoExperience(){
      this.offre.video_experience = null;
      this.postOffre()
    },
    addCapsule(caps) {
      this.offre.capsules.push(caps)
    }
  },
  computed: {
    isPublishable() {
      if(this.offre.type?.has_reservation && this.offre.dates){
        return true
      }
      return false
    },
    uploadVideoUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/video'
    },
    uploadImageUrl() {
      return process.env.VUE_APP_API + '/producteur/offre/upload/image/'+ this.offre.uuid
    },
    saveDatesUrl() {
      return process.env.VUE_APP_API + '/producteur/offre/dates/'+ this.offre.id
    },
    deleteDateUrl() {
      return process.env.VUE_APP_API + '/producteur/offre/date/'+ this.offre.id
    },
    offreSource() {
      return {
        id: this.offre.id,
        titre: this.offre.titre,
        duree: this.offre.duree,
        dates: this.offre.dates.map(d => {
          return {
            id: d.id,
            date: d.date
          }
        })
      }
    }
  },
  components: {
    VimeoUploader,
    FileUploader,
    DateSelector,
    CapsuleCreator,
  },
};
</script>

<style scoped>
@import "https://cdn3.devexpress.com/jslib/20.2.5/css/dx.common.css";
@import "https://cdn3.devexpress.com/jslib/20.2.5/css/dx.light.css";
</style>