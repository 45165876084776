<template>
	<div class="row">
		<div class="col-md-6">
		
			<div class="form-group">
			<label for="">Titre</label>
			<input type="text" class="form-control" v-model="v$.capsule.titre.$model" :class="{ 'form-error': v$.capsule.titre.$errors.length }">
			</div>

			<div class="form-group">
				<label for="">Message</label>				
				<ckeditor :editor="editor" :config="editorConfig" v-model="v$.capsule.description.$model" :class="{ 'form-error': v$.capsule.description.$errors.length }"></ckeditor>
			</div>
		
		</div>
		<div class="col-md-6">

			<h3>Video</h3>
			<VimeoUploader 
				v-model="capsule.video" 
				:uploadurl="uploadVideoUrl" 
				:videoid="capsule?.video?.fichier" />
			
			<hr>
			<h3>Image</h3> 
			<img v-if="capsule.image" :src="capsule.image.full_path" width="200" alt="">
			<FileUploader :uploadurl="uploadImageUrl" @add-document="addImageCapsule" />
		</div>
		
		<div class="col-md-12">                      
			<button v-on:click="postCapsule()" class="btn btn-secondary pull-right">Terminer</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core'
// import userService from '@/service/user'
import Capsule from "@/core/models/Capsule"
import VimeoUploader from "@/components/VimeoUploader";
import FileUploader from "@/components/FileUploader";
import { required, minLength } from '@vuelidate/validators'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
	name: "CapsuleCreator",
	emits: ['add-capsule'],
	props: [
        'offre',        
    ],
	setup () {
		return { v$: useVuelidate() }
	},
	data() {
		return {     
			loading: false,
			capsule: new Capsule(),
			editor: ClassicEditor,
			editorConfig : {
				toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
			}
		};
	},
	validations() {
		return {
			capsule: {
				titre: { 
					required,
					minLength: minLength(4)
				},
				description: { 
					minLength: minLength(4)
				},
			},		
		}
	},
	mounted() {
		this.capsule.offre = this.offre
	},
	computed: {
		uploadVideoUrl() {
			return process.env.VUE_APP_API + '/producteur/document/upload/video'
		},
		uploadImageUrl() {
			return process.env.VUE_APP_API + '/producteur/document/upload/image'
		},		
	},
	methods: {
		postCapsule() {
			this.v$.$touch()
			if( !this.v$.$invalid){
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});			
				axios
					.post(
						process.env.VUE_APP_API + "/producteur/capsule",
						this.capsule
					)
					.then((resp) => {
						// REDIRECT TO DASHBOARD						
						this.$emit('add-capsule', resp.data)
						loading.close();
						// this.$router.push('/offre/' + this.offre.uuid)
					})
					.catch((error) => {
						console.log("ERROR", error);
						loading.close();
					});
			}
		},
		addImageCapsule(doc) {			
			this.capsule.image = doc
		}
	},
	components: {
		VimeoUploader,
		FileUploader,
		ckeditor: CKEditor.component
	},
}
</script>